@import "src/utilities/variables.scss";
@import "src/styles/touch.scss";
@import "src/styles/colors.scss";

$reduction1: 2px;
$reduction2: 14px;
$reduction3: 10px;
$reduction4: 4px;

span,
img {
  user-select: none;
  /* Standard syntax */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

h1 {
  // Heading/Heading 1
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 64px;

  @media screen and (max-width: 576px) {
    // Heading/Heading 3
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    /* 150% */
    letter-spacing: 0.25px;
  }
}

h2 {
  // Heading/Heading 2
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.25px;

  @media screen and (max-width: 576px) {
    // Body 2
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

span.heading1 {
  // Heading/Heading 1
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 64px;
}

span.heading2 {
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.25px;
}

span.heading3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.25px;
}



span.heading3-md-heading1 {
  // Heading/Heading 3
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.25px;

  @media screen and (min-width: 576px) {
    // Heading/Heading 1
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px;
  }
}

span.heading2-md-heading1 {
  // Heading/Heading 2
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.25px;

  @media screen and (min-width: 576px) {
    // Heading/Heading 1
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px;
  }
}

span.subtitle3-md-heading2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.15px;

  @media screen and (min-width: 576px) {
    // Body 2
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.25px;
  }
}

span.subtitle2-md-heading1 {
  // Subtitle 2
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.15px;

  @media screen and (min-width: 576px) {
    // Heading/Heading 1
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px;
  }
}

span.subtitle3-md-subtitle1 {
  // Subtitle 3
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.15px;

  @media screen and (min-width: 576px) {
    // Subtitle 1
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0.15px;
  }
}

span.heading3-md-heading2 {
  // Heading/Heading 3
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.25px;

  @media screen and (min-width: 576px) {
    // Heading/Heading 2
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.25px;
  }
}

span.subtitle3-md-subtitle2 {
  // Subtitle 3
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.15px;

  @media screen and (min-width: 576px) {
    // Subtitle 2
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.15px;
  }
}

span.subtitle2-md-heading2 {
  // Subtitle 2
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.15px;

  @media screen and (min-width: 576px) {
    // Heading/Heading 2
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.25px;
  }
}

span.subtitle2-md-heading1 {
  // Subtitle 2
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.15px;

  @media screen and (min-width: 576px) {
    // Heading/Heading 1
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px;
  }
}

span.subtitle2-md-heading3 {
  // Subtitle 2
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.15px;

  @media screen and (min-width: 576px) {
    // Heading/Heading 3
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.25px;
  }
}

span.subtitle2-md-subtitle1 {
  // Subtitle 2
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.15px;

  @media screen and (min-width: 576px) {
    // Subtitle 1
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0.15px;
  }
}

span.body2-lg-subtitle3 {
  // Body 2
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  @media screen and (min-width: 997px) {
    // Subtitle 3
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
}

span.body2-lg-subtitle2 {
  // Body 2
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  @media screen and (min-width: 997px) {
    // Subtitle 2
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.15px;
  }
}

span.button3-lg-body2 {

  // button3
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.15px;

  @media screen and (min-width: 997px) {
    // body2
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

  }
}

span.body1-bold-md-body1 {
  // Body1 bold
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  @media screen and (min-width: 576px) {
    // Body1
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
}

span.body1-bold-md-subtitle1 {
  // Body1 bold
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  @media screen and (min-width: 576px) {
    // Subtitle 1
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0.15px;
  }
}

span.body1-bold-md-heading3-bold {
  // Body1
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 9px;

  @media screen and (min-width: 576px) {
    // Heading/Heading 3
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.25px;
  }
}

span.body1-md-subtitle1 {
  // Body1
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media screen and (min-width: 576px) {
    // Subtitle 1
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0.15px;
  }
}

span.body1-md-heading3 {
  // Body1
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media screen and (min-width: 576px) {
    // Heading/Heading 3
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.25px;
  }
}

span.body2-md-subtitle3 {
  // Body 2
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  @media screen and (min-width: 576px) {
    // Subtitle 3
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}

span.body2-md-body1 {
  // Body 2
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  @media screen and (min-width: 576px) {
    // Body1
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

span.body1-md-subtitle2 {
  // Body1
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media screen and (min-width: 576px) {
    // Subtitle 2
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.15px;
  }
}

span.body2-md-heading3 {
  // Body 2
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  @media screen and (min-width: 576px) {
    // Heading/Heading 3
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.25px;
  }
}

span.body2-md-subtitle1 {
  // Body 2
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  @media screen and (min-width: 576px) {
    // subtitle2
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0.15px;
  }
}

span.subtitle2-lg-heading1 {
  // Subtitle 2
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0.15px;

  @media screen and (min-width: 997px) {
    // Heading 1
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0.15px;
  }
}

span.body2-bold-md-subtitle1 {
  //body 2 bold
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;

  @media screen and (min-width: 576px) {
    // subtitle2
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0.15px;
  }
}

span.body2-md-subtitle2 {
  // Body 2
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  @media screen and (min-width: 576px) {
    // subtitle2
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.15px;
  }
}

span.body2-md-body1 {
  // Body 2
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  @media screen and (min-width: 576px) {
    // Body 1
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

span.body3-md-body1 {
  // Body 3
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */

  @media screen and (min-width: 576px) {
    // Body 1
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

span.body3-md-subtitle2 {
  // Body 3
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */

  @media screen and (min-width: 576px) {
    // subtitle2
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.15px;
  }
}

span.body3-md-body2 {
  // Body 3
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */

  @media screen and (min-width: 576px) {
    // Body 2
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

h3 {
  // Heading/Heading 3
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.25px;
}

h4 {
  // Subtitle/Subtitle 2
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.15px;
}

h5 {
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0.15px;
}

span.heading3 {
  // Heading/Heading 3
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.25px;
}

span.body1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

span.body1-bold {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

span.body2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

a.body2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

span.body2-bold {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

label.body2-bold {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

span.body3 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.body3 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

span.subtitle1 {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0.15px;
}

span.subtitle2 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.15px;
}

span.subtitle3 {
  // Subtitle 3
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

span.button2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.15px;
}

span.button3 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.15px;
}

span.button3-md-button2 {
  // Button 3
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.15px;

  @media screen and (min-width: 576px) {
    // Button 2
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.15px;
  }
}

span.body2-bold-md-body1-bold {
  //body2-bold
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;

  @media screen and (min-width: 576px) {
    //body1-bold
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
}

span.subtitle3-md-body1 {
  //subtitle3
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */

  @media screen and (min-width: 576px) {
    //body1
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

span.button2-md-button1 {
  // button-2
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.15px;


  @media screen and (min-width: 576px) {
    // button-1
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.15px;
  }
}

span.body3-md-body3-bold {
  // body3
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */

  @media screen and (min-width: 576px) {
    // body3-bold
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 150% */
  }
}

span.label {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 160% */
}

.bold {
  font-weight: 700 !important;
}

.semi-bold {
  font-weight: 500 !important;
}

span.link {
  cursor: pointer;
  font-weight: 700;
  text-decoration-line: underline;

  &:hover {
    color: var(--paylow-secondary-color);
  }
}

span.soft-link {
  cursor: pointer;
  text-decoration-line: underline;

  &:hover {
    color: var(--paylow-secondary-color);
  }
}

a {
  color: var(--paylow-primary-text-color);
  ;
}

.link-text {
  span {
    text-decoration: none;
  }

  span {
    &:hover {
      // text-decoration: underline;
      text-decoration-color: var(--paylow-primary-color);
      border-bottom: 2px solid var(--paylow-primary-color);
    }
  }

  text-decoration: none !important;

  &:hover {
    text-decoration: none !important;
  }
}

.payment {
  display: flex;
  align-items: baseline;

  span.payment-major {
    font-weight: 600;

    @media screen and (min-width:997px) {
      font-size: 17px;

    }

    @media screen and (max-width:500px) {
      font-weight: 600;
      font-size: 24px;
      // line-height: 36px;
    }
  }

  span.payment-minor {
    font-weight: 500;

    @media screen and (min-width:997px) {
      font-size: 14px;

    }

    @media screen and (max-width:500px) {
      font-weight: 400;
      font-size: 14px;
      // line-height: 22px;
    }
  }
}


.btn-gradient {
  width: 100%;
  color: white;

  outline: 2px solid transparent;
  background: linear-gradient(90deg, #EF4D81 0.01%, #F16474 24.01%, #F58F5C 74.02%, #F7A053 100.02%);
  border-radius: 6px;
  border-width: 0;

  transition: 0.25s;

  &:hover {
    // outline: 1px solid #4b4646d1;
  }
}

.btn-big {
  height: 48px;

  @media screen and (min-width: 997px) {
    height: 64px;
    width: 50%;
  }

  span {
    @extend .button2-md-button1;
    color: #FFFF;
  }
}

.spinner-regular {
  font-size: 16px;
}

.btn-hover-dark {
  &:hover {
    outline: 2px solid var(--paylow-primary-color);
  }
}

.gradient-text {
  background: linear-gradient(90deg, #EF4D81 0.01%, #F16474 24.01%, #F58F5C 74.02%, #F7A053 100.02%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-clear {
  height: 100%;
  background: #FFFFFF;
  border: 2px solid var(--paylow-light-color);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 60px;
  color: var(--paylow-primary-color);

  &:hover {
    background: var(--paylow-primary-color);
    color: #FFF;
  }

  span {
    @extend .button2-md-button1;
  }
}

.btn-dark {
  background-color: var(--paylow-primary-color);
  border-color: var(--paylow-primary-color);
  height: 48px;
  width: 100%;
  border-radius: 6px;

  &:hover {
    background-color: color-mix(in srgb, var(--paylow-secondary-color) 90%, white 10%);
    border-color: color-mix(in srgb, var(--paylow-secondary-color) 90%, white 10%);
  }

  &:focus,
  &:active {
    background-color: color-mix(in srgb, var(--paylow-secondary-color) 90%, white 10%);
    border-color: color-mix(in srgb, var(--paylow-secondary-color) 90%, white 10%);
  }

  span {
    @extend .button2-md-button1;
    color: #FFFFFF;
    text-align: center;
  }

  @media screen and (min-width: 576px) {
    height: 64px;
  }
}

.btn-submit {
  width: 100%;
  height: 48px;
  border-radius: 6px;

  span {
    @extend .button2-md-button1;
    color: #FFFFFF;
    text-align: center;
  }

  @media screen and (min-width: 576px) {
    height: 64px;
  }
}

.input-regular {
  box-sizing: border-box;
  padding: 0 24px;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid rgba(148, 146, 199, 0.5);
  border-radius: 6px;

  @media screen and (min-width: 576px) {
    height: 64px;
    width: 100%;
  }
}

.form-input {
  box-sizing: border-box;
  padding: 10px;
  background: #FFFFFF;
  border: 1px solid rgba(148, 146, 199, 0.5);
  border-radius: 6px;
}

.input-select {
  padding: 10px;
  // border: 2px solid #333;
  border-radius: 5px;
  // background-color: #f0f0f0;
  // color: #333;
  font-size: 16px;
}

/* Style the down arrow */
// .input-select:after {
//   content: "&#37;"; /* Down arrow symbol (you can replace this with your spinner icon) */
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   font-size: 16px;
// }

/* Optional: Style the options */
.input-select option {
  background-color: #fff;
  color: #333;
  font-size: 16px;
}

.new-main-content {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin: 16px 32px 24px 32px;
  z-index: 2;
  transition: 500ms;

  @media screen and (max-height: 640px) {
    margin: 16px 32px 24px 32px;
  }

  @media screen and (min-width: 997px) {
    margin: 48px 5vw 0 5vw;
  }

  @media screen and (min-width: 1024px) {
    margin: 48px 7vw 0 7vw;
  }

  @media screen and (min-width: 1200px) {
    margin: 48px 7vw 0 7vw;
  }

  @media screen and (min-width: 1400px) {
    margin: 48px 10vw 0 10vw;
  }

  @media screen and (min-width: 1500px) {
    margin: 48px 12vw 0 12vw;
  }

  @media screen and (min-width: 1600px) {
    margin: 48px 21vw 0 18vw;
  }

  &.expand-right {
    @media screen and (min-width: 997px) {
      margin-right: 3vw;
    }
  }
}

.content-padding {
  @media screen and (min-width: 997px) {
    padding-left: 54px;
  }
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin: 16px 32px 24px 32px;
  z-index: 2;
  transition: 500ms;

  @media screen and (max-height: 640px) {
    margin: 16px 32px 24px 32px;
  }

  @media screen and (min-width: 997px) {
    margin: 48px 5vw 0 5vw;
  }

  @media screen and (min-width: 1024px) {
    margin: 48px 7vw 0 7vw;
  }

  @media screen and (min-width: 1200px) {
    margin: 48px 7vw 0 7vw;
  }

  @media screen and (min-width: 1400px) {
    margin: 48px 10vw 0 10vw;
  }

  @media screen and (min-width: 1500px) {
    margin: 48px 12vw 0 12vw;
  }

  @media screen and (min-width: 1600px) {
    margin: 48px 21vw 0 21vw;
  }



  &.expand-right {
    @media screen and (min-width: 997px) {
      margin-right: 3vw;
    }
  }
}

.right {
  display: none;

  @media screen and (min-width: 500px) {
    display: flex;
  }
}


.secondary-content {
  max-width: 40%;

  .main-image {
    max-height: 61vh;
    max-width: 40vw;
  }
}

.clickable {
  cursor: pointer;
}

.scale-hover {
  transition: 0.5s;

  &:hover {
    transform: scale(1.15);
  }
}

.dark-text {
  color: var(--paylow-primary-color);
}

.regular-text {
  color: var(--paylow-secondary-color);
}

.light-text {
  color: var(--paylow-light-color);
}

::ng-deep {
  .ngb-dp-weekday .ngb-dp-navigation-chevron {
    color: var(--paylow-primary-color);
  }

  .form-check-input:checked {
    background-color: var(--paylow-primary-color);
    border-color: var(--paylow-primary-color);
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--paylow-primary-color);
    cursor: pointer;
  }
}



.paylow-tag {
  border-radius: 10rem;
  display: inline-block;
  padding: 0.25em 0.4em;
  justify-content: center;
  align-items: center;
  width: fit-content;
  color: #FAFAFA;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: var(--paylow-secondary-color);

  transition: 0.5s;

  &.success {
    background-color: green;
  }

  &.inactive {
    background-color: rgb(126, 126, 126);
  }
}

.overflow-md-unset {
  overflow: auto;

  @media screen and (min-width: 576px) {
    overflow: unset;
  }
}




// Form

.form {
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}


// Colors
$color-primary-60: var(--paylow-secondary-color);

.orange50 {
  color: #F26F6B;
}

.text-primary60 {
  color: $color-primary-60;
}

// height breakpoints

.gap-hsm-8 {
  @media screen and (max-height: 600px) {
    gap: 8px !important;
  }
}


.gap-hsm-8 {
  @media screen and (max-height: 600px) {
    gap: 8px !important;
  }
}


.touch-clicked {
  position: relative;
}

.h-100-lg-unset {
  height: 100% !important;

  @media screen and (min-width: 997px) {
    height: unset !important;
  }
}

.touch-clicked::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0.4;
  // pointer-events: none;
  border-radius: 8px;
}