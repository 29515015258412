@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "scss/bs-main.scss";
@import "utilities/variables";
@import "styles/theming";

@import "utilities/paylow-form";
@import "styles/paylow-global";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons";

html, body {
  height: 100%;
  width: 100%;
  background-color: #F3F5FB;
  /* Disables double-tap zoom */
  touch-action: none;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Regular'), local('Gilroy-Regular'),
    url('assets/fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
    url('assets/fonts/Gilroy/Gilroy-Semibold.woff2') format('woff2'),
    url('assets/fonts/Gilroy/Gilroy-Semibold.woff') format('woff'),
    url('assets/fonts/Gilroy/Gilroy-Semibold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Bold'), local('Gilroy-Bold'),
    url('assets/fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Extra Bold'), local('Gilroy-ExtraBold'),
    url('assets/fonts/Gilroy/Gilroy-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url('assets/fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url('assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url('assets/fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

.flex-col-center {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.bold {
  font-weight: 600;
}

.extra-bold {
  font-weight: 800;
}


.debug-center {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 20px;
  background-color: rgba(255, 0, 0, 0.25);
  z-index: 200;
}


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}



.text-align-center {
  text-align: center;
}


//input.form-control {
//  &.ng-invalid {
//    &.ng-touched {
//      outline: 1px solid #ff2e3d;
//    }
//  }
//}


form.ng-submitted {
  @extend .was-validated;
}

.thin-line {
  line-height: 0.5;
}

.text-xl {
  font-size: 24px !important;
}


.text-m {
  font-size: 16px;
}

.text-xm {
  font-size: 18px;
}

.text-l {
  font-size: 20px !important;
}

.text-s {
  font-size: 14px;
}

.text-xs {
  font-size: 12px;
}

.color-white {
  color: #FFFFFF;
}

.color-gray {
  color: #8d8f8d;
}

.hidden2 {
  visibility: hidden;
  opacity: 0;
  display: none;
  animation: hideElement 1s 1 linear;
  //will-change: opacity, transform, display;

  @keyframes hideElement {
    0% {
      //display: block;
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}

.visible {
  visibility: visible;
  opacity: 1;
  animation: showElement 1s 1 linear;


  @keyframes showElement {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.color-success {
  color: rgb(75, 116, 3);
}

.cursor-pointer {
  cursor: pointer;
}


.paylow-card {
  background: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border-radius: 16px;
  padding: 16px;
}


.bg-color-paylow-primary {
  background-color: var(--paylow-secondary-color) !important;
  transition: 0.1s;
  color: #FFFFFF !important;

  &:hover {
    background-color: #625cb0 !important;
    transition: 0.1s;
    color: #FFFFFF !important;
    ;
  }
}

.d-flex2 {
  display: flex;
}

.weight-600 {
  font-weight: 600;
}


.paylow-clickable {
  cursor: pointer;

  &.selected,
  &:focus,
  &:hover {
    outline: 3px solid #33A2FF;
  }
}

.back-btn {
  padding: 0 !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fcf;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fcf transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid rgb(148, 146, 199);
  border-color: rgb(148, 146, 199) transparent rgb(148, 146, 199) transparent;
  animation: lds-dual-ring 2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}


.paylow-tag {
  border-radius: 10rem;
  font-size: 75%;
  display: inline-block;
  padding: 0.25em 0.4em;
  justify-content: center;
  align-items: center;
  width: fit-content;
  color: #FAFAFA;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: var(--paylow-secondary-color);
}

.bg-color-telecom {
  background-color: var(--paylow-secondary-color);
}

.bg-color-entertainment {
  background-color: #0d6efd;
}

.bg-color-music {
  background-color: #ef4c82;
}


::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: rgba(148, 146, 199, 0.2);
}

::-webkit-scrollbar-thumb {
  background-color: var(--paylow-light-color);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--paylow-secondary-color);
}

* {
  @media screen and (max-width:997px) {
    scrollbar-width: thin;
    scrollbar-color: var(--paylow-light-color) #f1f1f1;
  }
}