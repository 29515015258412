// @import "../../node_modules/bootstrap/scss/bootstrap";
// Import our variable overrides.
// Go here to set custom values for Bootstrap vars
@import "./bs-variables";

// Import Bootstrap functions, vars and mixins
@import "../../node_modules/bootstrap/scss/functions";

$offcanvas-height: 50vh;


@import "../../node_modules/bootstrap/scss/variables";

@import "../../node_modules/bootstrap/scss/mixins";
// Import our map amends
// Go here to run any Sass map amends on Bootstrap Sass maps
@import "./bs-maps";

$border-radius:               8px;

$carousel-indicator-active-bg:       var( --paylow-secondary-color);
$carousel-control-color:             var( --paylow-secondary-color);
$carousel-indicator-width:           4px;
$carousel-control-icon-width:        1rem;
$carousel-control-width:             8%;
$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
$progress-bar-bg-success:            var( --paylow-secondary-color) ;

// $offcanvas-bg: var( --paylow-secondary-color);
$offcanvas-color: #fff;
$offcanvas-bg-color: var(--color-primary-10);
$offcanvas-vertical-height: 90%;
// $carousel-control-color:red;

$component-active-bg: var( --paylow-secondary-color);

$popover-max-width:                 500px;
$popover-arrow-width:               40px;
$popover-arrow-height:              30px;

// toast style 

$toast-max-width:                   400px;
$toast-padding-x:                   1.2rem;
$toast-font-size:                   1.2rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 997px,
  xl: 1200px,
  xxl: 1400px
);
// accordion 

$accordion-button-focus-box-shadow: white;
$accordion-border-width: white;





// Import our utility overrides
// Go here to amend or set custom utilities using the Bootstrap API
// @import './utilities/bootstrap-api-utilities';
// Bootstrap main import

@import "../../node_modules/bootstrap/scss/bootstrap";

:root {
    @each $spacer, $value in $spacers {
        --theme-spaces-#{$spacer}: #{$value};
    }
}


// xs: 0,
// sm: 576px,
// md: 768px,
// lg: 997px,
// xl: 1200px,
// xxl: 1400px