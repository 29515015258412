$error-background: #ffd7d4;
$error-border: #ff8280;

ngb-popover-window {
  &.error-popover {

    border: 1px solid $error-border;

    div.popover-body {
      background-color: $error-background;
      border-radius: 8px;
    }

    .popover-arrow {

      &::before {
        border-top-color: $error-border !important;
      }

      &::after {
        border-top-color: $error-background !important;
      }
    }

  }
}

input.form-control {
  &.ng-invalid.ng-touched {
    outline: 1px solid $error-border;

    &:focus {
      outline: none;
    }
  }
}
