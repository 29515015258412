@import "src/utilities/variables";
@import "src/styles/system-design.scss";
@import "src/styles/new-beta.scss";

.btn-outline {
    background: #FFFFFF;
    border: 2px solid var(--paylow-light-color);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 4px 8px;
    color:  var(--paylow-primary-text-color);
    cursor: pointer;

    &:hover {
        background: var(--paylow-second-light-color);
        border: 2px solid var(--paylow-second-light-color);

        color: #fff;
    }

    &.dark {
        color: #fff;
        background: var(--paylow-light-color);
    }

    &.selected {
        background: var(--paylow-second-light-color);
    }

}


.pbtn {
    width: 100%;
    color: white;
  
    outline: 2px solid transparent;
    border-radius: 6px;
    border-width: 0;
  
    transition: 0.25s;
  
    &:hover {
      outline: 2px solid #FFFFFF;
    }

    &.gradient {
        background: linear-gradient(90deg, #EF4D81 0.01%, #F16474 24.01%, #F58F5C 74.02%, #F7A053 100.02%);
    }
    &.success {
        background: green;
    }


  }
