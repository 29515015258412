@import "../utilities/variables";
@import "new-beta";

.guider-popover .popover-header {
}


.popover {
  background-color: transparent;
  border: unset;
  max-width: 314px;
}

.guider-popover[data-popper-placement^=bottom] > .popover-arrow::after {
    top: 3.5px;
    border-bottom-color: #FFF;
}

.guider-popover[data-popper-placement^=bottom] .popover-arrow::before {
    top: 1px;
    border-bottom-color: #f48065;
}

.guider-popover[data-popper-placement^=right] > .popover-arrow::after {
    top: -3.5px;
    right: -3.5px;
    border-right-color: #FFF;
}

.guider-popover[data-popper-placement^=right] .popover-arrow::before {
    top: -3.5px;
    right: 1px;
    border-right-color: #f48065;
}

.guider-popover[data-popper-placement^=left] > .popover-arrow::after {
    top: -3.5px;
    left: -3.5px;
    border-left-color: #FFF;
}

.guider-popover[data-popper-placement^=top] .popover-arrow::before {
    top: 1px;
    border-top-color: #f48065;
}

.guider-popover[data-popper-placement^=top] > .popover-arrow::after {
    top: -3.5px;
    border-top-color: #FFF;
}

.guider-popover[data-popper-placement^=left] .popover-arrow::before {
    top: -3.5px;

    @media screen and (min-width: 997px) {
        left: 1px;
    }

    border-left-color: #f48065;
}

.guider-popover .popover-body {
    
    background-color: #FFF;
    border-radius: 20px;
    // font-family: 'Montserrat';
    font-family: 'Poppins', serif;
    color:  var(--paylow-primary-color);
    
    min-width: 314px;
    padding: 10px;
    @media screen and (max-width: 997px) {
        min-width: 280px;
        padding: 8px;
    }
}

.guider-popover {
    position: relative;
}

.guider-popover::before {
    content: '';
    position: absolute;
    top: -2px; /* Adjust these values based on the desired border size */
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    background: linear-gradient(90deg, #EF4D81 0.01%, #F16474 24.01%, #F58F5C 74.02%, #F7A053 100.02%);
    border-radius: 21px; /* Match the popover's border-radius */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

.tooltip-inner {
    font-size: 11px !important; 
    
    @media screen and (max-width: 997px) {
        font-size: 11px !important; 
    }

    background-color:  var(--paylow-primary-text-color);
    color: white;
  }


.modal-medium .modal-dialog {
    
    max-width: 40vw;
    margin: auto;


}


.modal-padded .modal-content {
    padding: 30px;
}


.topbar[data-popper-placement^=bottom] > .popover-arrow::after {
    position: absolute;
    top: 35px;
    border-bottom-color: var( --paylow-secondary-color);
    right: 20px;
  
}
.topbar[data-popper-placement^=bottom] > .popover-arrow::before {
    position: absolute;
    top: 35px;
    border-bottom-color: var( --paylow-secondary-color);
    right: 20px;
}


.topbar .popover-body {
    position: absolute;
    top: 32px;
    right: -98px;
}
