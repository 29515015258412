$primary-color: #393778;
$secondary-color: #5a54a4;
$color3: #9492c7;
$break-point7: 436px;
$break-point2: 1400px;
$break-point5: 990px;
$break-point6: 680px;

$paylow-dark-purple: #393778;
$paylow-purple: #5a54a4;
$paylow-light-purple: #9492C7;
$paylow-peach: #F26F6B;

$paylow-main-background: #393778;
$paylow-main-text: #fff;


:root {
    --paylow-primary-color: var(--default-primary-color, #393778); // #393778 0b360d
    --paylow-primary-text-color: var(--default-primary-text-color, #393778); // #393778 0b360d
    --paylow-secondary-color: var(--default-secondary-color, #5a54a4); // 5a54a4 3a6b3c
    --paylow-trinary-color: var(--default-secondary-color, #9492C7); // 5a54a4 3a6b3c
    
    --paylow-light-color: var(--default-light-color, #9492c7); //9492c7 7aad7d
    --paylow-second-light-color: var(--default-second-light-color, #bbbadb); // bbbadb  addaaf

    --paylow-peach-color: var(--default-peach-color, #F26F6B);


}