.touched {
    animation: touchAnimation 0.3s ease-in-out;
}

@keyframes touchAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.97);
    }

    100% {
        transform: scale(1);
    }
}