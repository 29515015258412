@import "src/utilities/variables";


.w-100-md-50 {
  width: 100%;

  @media screen and (min-width: 576px) {
    width: 50%;
  }
}

.w-lg-100 {
  @media screen and (max-width: 997px) {
    width: 100%;
  }
}

.left-small {
  padding: 6px;
  display: flex;
  
  @media screen and (min-width: 576px) {
    padding-left: 58px;
    transform: translateX(-58px);
  }
}

.animation-container {
  overflow-x: hidden;
  overflow-y: hidden;

  // overflow-y: scroll;

  @media screen and (min-width: 997px) {
    padding-left: 64px;
    transform: translateX(-64px);
  }
  // width: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;

}

.responsive-scale-left {
  transform-origin: left center;

  @media screen and (min-width: 997px) and (max-width: 1600px) {
    transform: scale(0.9);
  }
}
.responsive-scale-center {
  transform-origin: center center;

  @media screen and (max-width: 1600px) {
    transform: scale(0.8);
  }
}

.back-and-text {
  display: flex;
  flex-direction: row;

  @media screen and (min-width: 997px) {
    transform: translateX(-58px);
    gap: 26px !important;
  }
}

.back-and-text-large {
  display: flex;
  flex-direction: row;

  @media screen and (min-width: 997px) {
    transform: translateX(-64px);
    gap: 24px  !important;
  }
}

.btn-back {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: var( --paylow-secondary-color);
  width: 23px;
  height: 23px;

  @media screen and (min-width: 997px) {
    width: 40px;
    height: 40px;
  }


  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: color-mix(in srgb, var(--paylow-secondary-color) 90%, white 10%);
      transition: 0.3s;
  }
}

.btn-back-small {
  @extend .btn-back;

  @media screen and (min-width: 997px) {
    width: 32px;
    height: 32px;
  }


  svg {
    height: 73%;
    width: 32px;
    padding-right: 1px;
  }
}

.modal-close-btn {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  border: none;
  background: transparent;

  outline: none;
  box-shadow: none;

  top: 5px;
  right: 5px;
  img {
      height: 18px;
  }

  @media screen and (min-width: 576px) {
      top: 18px;
      right: 18px;

      img {
          height: 16px;
      }
  }
}


.panel-section-left {
  background-color: #FFF;

  @media screen and (min-width: 576px) {
    background-color: #F3F5FB;
  }

  min-width: 40%;
}

.panel-section-right {
  width: 100%;
  flex-grow: 1;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}


.loader-gradient{
  display: block;
  position: relative;
  height: 12px;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 10px;
  overflow: hidden;
}
.loader-gradient::after {
  content: '';
  width: 80%;
  height: 100%;
  background: linear-gradient(90deg, #EF4D81 0.01%, #F16474 24.01%, #F58F5C 74.02%, #F7A053 100.02%);
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  border-radius: 10px;

  animation: animloader 1.2s linear infinite;
}

@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}


.dashboard-padding {

  // 167 * COUNT + 8 * (COUNT - 1) + 16 
  padding: 0 calc( (100vw - ( 6 * 167px + 5 * 8px + 16px + 10px ) ) / 2);


}

.paylow-gradient-border {
  @media screen and (min-width: 997px) {
    &::before {
      content: "";
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px;
      border: 2px solid transparent;
      background: linear-gradient(90deg, #EF4D81, #F16474 24%, #F58F5C 74%, #F7A053 100%) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }
  }

}

.empty-placeholder {
  // background-color: rgba(0,0,0,0.14);
  color:  var(--paylow-primary-color);
  border-radius: 8px;
  border: 2px dashed var(--paylow-primary-color);

  background: linear-gradient(-45deg, #c6c6c6 40%, rgb(229, 229, 229) 50%, #c6c6c6 60%);
  background-size: 300%;
  background-position-x: 100%;
  animation: shimmer 2s infinite linear;
}

@keyframes shimmer {
  to {
     background-position-x: 0%
  }
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

