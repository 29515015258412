// @import "../../node_modules/bootstrap/scss/bootstrap";

@import "src/utilities/variables";
@import "src/styles/system-design.scss";
@import "src/styles/new-beta.scss";

$primary-color: var( --paylow-primary-color);
$primary-hover-color: color-mix(in srgb, var(--paylow-primary-color) 90%, white 10%);
$primary-focus-color: rgba(var(--paylow-primary-color), 0.5);
$light-color: #FFFFFF;
$light-border-color:  var(--paylow-primary-text-color);

:root {
  --bs-modal-border-radius: 8px;
}

.btn-primary {
  background-color: var(--paylow-primary-color) !important;
  border-color: var(--paylow-primary-color) !important;

  &:hover {
    background-color: $primary-hover-color !important;
    border-color: $primary-hover-color !important;
  }

  &:focus,
  &:active {
    background-color: $primary-focus-color;
    border-color: $primary-focus-color;
    box-shadow: 0 0 0 0.2rem $primary-focus-color;
  }
}


.toast {
  font-family: 'Poppins', sans-serif;
}

.toast-header {
  background-color: var(--paylow-light-color);
  color: white
}

.modal-80vh {
  .modal-content {
    background-color: #F3F5FB;
    padding: 10px;
    border-radius: 8px;
    height: 80vh;

    @media screen and (max-width: 997px) {
      height: 95vh;
    }
  }
}

.modal-60vh {
  .modal-content {
    background-color: #F3F5FB;
    padding: 10px;
    border-radius: 8px;
    height: 95vh;
    max-height: 700px;
  }
}

ngb-modal-window {
  .modal-content {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border: none;
    width: 100%;

    @media screen and (max-width: 500px) {
      // width: 73%;
      margin: 0 auto;
    }
  }
}

::ng-deep {
  .nav-link {
    background-color: #C9C8E2 !important;
    padding: 4px 14px!important;

      flex: 1 !important;
       flex-grow: 1 !important;
       padding: 16px;
       font-size: 14px;
       border-radius: 8px;
      // border-radius: 0% !important;
      // border-bottom-left-radius: 0% !important;
      // border-top-right-radius: 0% !important;
      // border-bottom-right-radius: 0% !important;
      border-bottom: 1px solid #dfdede !important;
      box-shadow: rgb(128 125 125 / 24%) 0 3px 8px;
  }

  .nav-link.active {
    color:  var(--paylow-primary-text-color) !important;
    background-color: #F3F5FB !important;
    border-bottom:  0px solid #dfdede!important;
    box-shadow: rgba(0, 0, 0, 0) 0 3px 8px;

  }

  .nav-link.hover {
    color:  var(--paylow-primary-color);
  }

}
