@import "../utilities/variables";

// $mq-desktop: 1400px;
$mq-laptop: 1280px;
$mq-tablet: 1100px;
// $mq-mobile: 1400px;

h1.new-beta {
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0;
}

h2.new-beta {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  white-space: pre-line;
}

h3.new-beta {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  text-align: left;
}

h4.new-beta {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: left;
}

h5.new-beta {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
}

.paylow-light-purple-text {
  color: var(--paylow-trinary-color);
}

.paylow-purple-text {
  color: var(--paylow-secondary-color);
}

.paylow-dark-purple-text {
  color:  var(--paylow-primary-color);
}

.paylow-peach-text {
  color: $paylow-peach;
}

.paylow-gradient-border {
  @media screen and (min-width: 997px) {
  &::before {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    border: 2px solid transparent;
    background: linear-gradient(90deg, #EF4D81, #F16474 24%, #F58F5C 74%, #F7A053 100%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
}
}

.ellipsis-text-container {
  overflow: hidden;

  h1, h2, h3, h4, h5, h6 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}